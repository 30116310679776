.project-wrapper {
    position: relative;

    .portfolio-carousel-active {
        .single-project {
            overflow: hidden;

            .project-contents {
                background-color: #f7f7f7;
                border-radius: 10px;
                position: relative;
                margin-top: 60px;
            }

            .project-details {
                padding: 100px 0px;

                @media (max-width: 991px) {
                    padding: 50px;
                }

                .project-meta {
                    .project-cat {
                        margin-right: 30px;
                    }

                    .client-info {
                        span {
                            color: #000;
                            font-weight: 600;
                            font-size: 22px;
                            padding-right: 5px;
                        }
                        i {
                            color: #000;
                            font-weight: 500;
                        }
                    }
                    h2{
                        font-size: 22px;
                    }
                }

                h2 {
                    font-size: 40px;
                    line-height: 50px;
                    margin-top: 5px;

                    @media (max-width: 1199px) {
                        font-size: 32px;
                        line-height: 130%;
                    }

                    @media (max-width: 767px) {
                        font-size: 30px;
                    }
                }

                p {
                    margin-top: 15px;
                    font-size: 26px;
                }

                .read-btn {
                    margin-top: 35px;
                }
            }

            .project-thumbnail {
                position: relative;
                height: 512px;
                background-color: #dddddd;
                border-radius: 10px;
                margin-top: -60px;
                overflow: hidden;
                background-size: cover;

                @media (max-width: 991px) {
                    border-radius: 0px;
                    height: 750px;
                    background-position: center;
                }

                @media (max-width: 767px) {
                    height: 350px;
                }

                .popup-gallery {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    img {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }

    .project-carousel-nav {
        display: flex;
        position: absolute;
        right: 95px;
        bottom: 0;
        z-index: 9999;
        overflow: hidden;
        border-radius: 10px;
        transform: translateY(50%);

        @media (max-width: 991px) {
            right: 50px;
        }

        div {
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            font-size: 24px;
            color: #fff;
            transition: all 0.4s ease-in-out;
            background: $theme-color;
            cursor: pointer;

            @media (max-width: 991px) {
                width: 60px;
                height: 60px;
                line-height: 60px;
                font-size: 20px;
            }

            &.owl-next {
                background: $theme-dark;
            }
        }
    }
}


.portfolio-carousel-wrapper {
    &.owl-carousel {
        z-index: 0;
        padding-left: 20px;
        padding-right: 20px;
    }

    .center {
        .single-project-item {
            
            .project-details {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .single-project-item {
        margin-top: 30px;
        margin-bottom: -80px;


        .project-thumb {
            height: 450px;
            background-color: #eee;
            position: relative;
            background-position: bottom;
            background-size: cover;
            

            @media (max-width: 991px) {
                height: 250px;
            }

            @media (max-width: 767px) {
                height: 220px;
              }
        }

        .project-details {
            position: relative;
            background-color: #fff;
            width: 90%;
            margin: 0px auto;
            z-index: 3;
            box-shadow: $box-shadow;
            padding: 25px 35px;
            margin-top: -60px;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: all 0.4s ease-in-out;
            transform: translateY(30px);

            &::before {
                position: absolute;
                width: 90%;
                left: 50%;
                bottom: -10px;
                transform: translateX(-50%);
                background-color: $theme-color-3;
                z-index: -1;
                content: "";
                height: 10px;
            }

            .icon {
                display: inline-block;
                position: absolute;
                right: 30px;
                top: 0;
                padding: 10px 30px;
                background-color: $theme-color-3;
                color: #001248;
                text-align: center;
                transform: translateY(-50%);
                -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
                clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
            }

            .meta {
                span {
                    &:last-child {
                        color: #001248;
                        font-weight: 600;
                    }
                }
            }

            h3 {
                color: #001248;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: $theme-color-3;
                }
            }
        }
    }
}

.portfolio-wrapper{
    background-image: url("../../img/portfolio/bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}


.single-approch-card,
.single-service-card {
    padding: 15px;
    background-color: #fff;
    position: relative;
    box-shadow: 0px 30px 60px 0px rgba(52, 52, 52, 0.04);
    margin-top: 30px;

    .card-thumb {
        height: 200px;
        position: relative;
        transition: all 0.3s ease-in-out;
    }

    .content {
        height: 350px;
        padding: 30px 35px;
        background-color: #f7f7f7;
        position: relative;

        @media (max-width: 991px) {
            height: 280px;
        }
      
        @media (max-width: 767px) {
            height: 280px;
        }

        @media (max-width: 600px) {
            padding: 30px;
        }

        .case-cat {
            position: absolute;
            right: 0;
            bottom: 100%;

            a {
                display: inline-block;
                padding: 20px;
                line-height: 1;
                font-size: 24px;
                background-color: $theme-color;
                position: relative;

                &:hover {
                    color: #fff;
                    background: $theme-color-2;
                }
            }
        }

        h3 {
            font-size: 22px;
            margin-bottom: 5px;
            text-transform: capitalize;

            &:hover {
                a {
                    color: $theme-color;
                }
            }
        }

        .read-btn {
            font-size: 16px;
            line-height: 1;
            text-transform: capitalize;
            margin-top: 20px;
            display: block;

            &:hover {
                color: $theme-color-3;
                padding-left: 5px;
            }

            i {
                margin-left: 5px;
            }
        }
    }
}
