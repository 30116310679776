/* ----------------------------------
	About Us Section Styles
 ------------------------------------ */



.about-wrapper {
  p{
    color: black;
    font-size: 20px !important;
  }
  @media (max-width: 991px) {
    text-align: center;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
  .block-contents {
    h1 {
      color: $theme-color;
      font-size: 56px;

      @media (max-width: 1199px) {
        font-size: 40px;
        line-height: 130%;
        text-align: center;
      }

      @media (max-width: 767px) {
        font-size: 32px;
        text-align: center;
      }
    }

    h4 {
      border-color: $black;
      font-weight: 400;
      padding: 20px;
      text-align: start;

      @media (max-width: 1199px) {
        padding: 5px 15px;
        font-size: 18px;
        margin: 20px 0px;
      }
    }

    span {
      display: block;
      color: $theme-color-2;

      b {
        color: $second-color;
      }
    }
  }
}

.about-promo {
  background-size: 100%;
  display: inline-block;
  text-align: center;
  width: 520px;
  height: 380px;
  background-repeat: no-repeat;

  @media (max-width: 991px) {
    height: 380px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 280px;
  }

  .skill-popup-video {
    border-width: 10px;
    border-color: white;
    border-style: solid;
    border-radius: 50%;
    background-color: #bfbfbf;
    box-shadow: 0px 10px 60px 0px $theme-color;
    width: 190px;
    height: 190px;
    z-index: 2;
    position: absolute;
    bottom: 60px;
    right: -85px;

    @media (max-width: 585px) {
      transform: translateX(-15%);
      -webkit-transform: translateX(-15%);
      -moz-transform: translateX(-15%);
      -ms-transform: translateX(-15%);
      -o-transform: translateX(-15%);
    }
  }

  .video-play-btn {
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: $theme-color;
    color: #fff;
    border-radius: 50%;
    line-height: 54px;

    a {
      &:hover {
        color: #fff;
      }
    }
  }
}


.promo-featured-wrapper {
  p{
    color: black;
    font-size: 20px !important;
  }
  span{
    color: black;
  }
  @media (max-width: 1199px) {
    img {
      width: 100%;
    }
  }

  .promo-slide {
    .slide-item {
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin-top: 10px;
      margin-bottom: 10px;
      .img{
        border-radius: 5px;
        height: auto;
        height: auto;
      }
    }
  }
}
