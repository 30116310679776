/* ----------------------------------
  Service Section Styles
 ------------------------------------ */
.services-1 {
    p{
        color: black;
        font-size: 20px !important;
      }
    padding-bottom: 75px;
    
    @media (max-width: 991px) {
        padding-bottom: 100px;
    }

    @media (max-width: 991px) {
        padding-bottom: 100px;
    }
    

    .container * {
        padding: 15px;
        
    }
    
        .row * {
            padding: 0;
        }
        

        .table-service {
            display: inline-block;
            position: relative;
            margin-top: 30px;
            margin-bottom: 10px;
            margin-left: 10px;
            margin-right: 10px;
            width: 23%;
            height: 590px;
            background-color: #fff;

            .img{
                width: 100%;
                height: auto;
            }

            @media (max-width: 991px) {
                height: 580px;
            }
      
            @media (max-width: 767px) {
            }
        }
        .table-service-content{
            padding: 20px;
        }

        h3{
            color: #960000;
        }
        p{
            color: #000;
        }

        @media (max-width: 820px) {
            .table-service {
                width: 47%;
            }
        }

        @media (max-width: 500px) {
            .table-service {
                width: 95%;
            }
        }

    
    

    // .table-service{
    //     color: #000;
    //     height: 100px;
    //     h3{
    //         color: #960000;
    //     }
    //     .table-service-text{
    //         padding-left: 10px;
    //         padding-right: 10px;
    //     }
    //     .table-service-img{
    //         padding-top: 50px;
    //         padding-left: 10px;
    //         padding-right: 10px;
    //     }
    //     .table-service-text *{
    //         padding-left: 30px;
    //             padding-right: 30px;
    //             padding-top: 20px;
    //             padding-bottom: 10px;
    //         background-color: #fff;
    //     }
    // }
    // tr * {
    //     vertical-align: top;
        
    // }
    
}

.single-service-item {
    p{
        color: black;
        font-size: 20px !important;
      }
    &.service-1 {
        margin-top: 50px;
        text-align: center;
        padding: 60px 35px;
        border-radius: 10px;
        position: relative;
        z-index: 1;
        color: #fff;
        background: #960000;

        &:hover,
        &.active {
            .service-bg {
                opacity: 1;
                visibility: visible;
            }

            .icon {
                color: $theme-color;
                background-color: #960000;
            }

            h3 {
                color: #fff;
            }

            a {
                background: $theme-color;
                color: #fff;
                width: 60%;

                @media (max-width: 767px) {
                    width: 50%;
                }

                span {
                    display: inline;
                }
            }
        }

        .service-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            left: 0;
            top: 0;
            content: "";
            z-index: -1;
            border-radius: 10px;
            visibility: hidden;
            transition: all 0.3s ease-in-out;
            overflow: hidden;

            &::before {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.8;
                left: 0;
                top: 0;
                content: "";
                background-color: #000;
            }
        }

        .icon {
            width: 120px;
            height: 120px;
            margin: 0px auto;
            margin-bottom: 30px;
            display: inline-block;
            border-radius: 50%;
            color: #fff;
            font-size: 48px;
            background-color: $theme-color;
            line-height: 120px;
            transition: all 0.3s ease-in-out;
        }

        h3 {
            font-size: 26px;
            letter-spacing: -1px;
            transition: all 0.3s ease-in-out;
            color: #fff;

            @media (max-width: 767px) {
                font-size: 22px;
            }
        }

        a {
            display: inline;
            border-radius: 10px;
            background-color: #f6f6f6;
            padding: 19px 25px;
            line-height: 1;
            font-weight: 700;
            text-transform: capitalize;
            color: #000;
            position: absolute;
            bottom: 0;
            transform: translate(-50%, 50%);

            @media (max-width: 767px) {
                padding: 18px 24px;
                font-weight: 500;
            }

            span {
                display: none;
                margin-right: 15px;
            }
        }
    }
}


.our-approch-wrapper{
    p{
        color: black;
        font-size: 20px !important;
      }
}

.our-service-wrapper{
    p{
        color: black;
        font-size: 20px !important;
      }
}


