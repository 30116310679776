.section-title {
    position: relative;
    margin-top: -5px;

    &.style-2 {
        p {
            color: $theme-color-2;
        }

        h1 {
            color: $second-color;
        }
    }

    span {
        position: absolute;
        content: "";
        font-weight: 700;
        font-size: 160px;
        letter-spacing: -3px;
        color: #fff;
        text-transform: capitalize;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        bottom: -5px;
        line-height: 1;

        @media (max-width: 1199px) {
            font-size: 120px;
        }
        @media (max-width: 767px) {
            font-size: 80px;
        }
    }

    p {
        margin-bottom: 5px;
        text-transform: capitalize;
        color: #000;
    }

    h1 {
        text-transform: capitalize;
        color: #000;
    }
}


.section-title-3 {
    p {
        background-color: $theme-color;
        padding: 12px 30px;
        line-height: 1;
        color: #fff;
        text-transform: capitalize;
        display: inline-block;
        -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        margin-bottom: 15px;
        font-weight: 500;
    }

    h1 {
        color: #001248;
        background-color: $theme-color;
        padding: 12px 90px;
        line-height: 1;
        color: #fff;
        text-transform: capitalize;
        display: inline-block;
        -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        margin-bottom: 15px;
        font-weight: 500;
    }
}

// Section Padding - Change with your need.
.section-padding {
    padding: 75px 0px !important;

    @media (max-width: 991px) {
        padding: 100px 0px;
    }

    @media (max-width: 767px) {
        padding: 80px 0px;
    }
}


.section-bg-img{
    background-image: url(https://static.thairath.co.th/media/dFQROr7oWzulq5Fa4MDkrMJReV6hZm5h0IISb5EIx6bQmVsj6G5jeE74metQSWfDhuE.webp);
    background-position: top;
  background-repeat: no-repeat;
  background-size:cover;
  height: 100%;
  top: 0;
  width: 100%;
  position:fixed;
  filter: blur(10px);
}

.section-bg {
    background-color: $section-bg;
    z-index: -1;
}

.theme-bg {
    background-color: $theme-color;
}

.page-banner-wrap {
    background-color: $theme-color;
    padding-bottom: 90px;
    padding-top: 120px;
    position: relative;
    z-index: 1;

    @media (max-width: 991px) {
        padding-bottom: 80px;
        padding-top: 100px;
    }

    @media (max-width: 767px) {
        padding-bottom: 70px;
        padding-top: 70px;
    }

    &::before {
        position: absolute;
        left: 0;
        content: "";
        top: 0;
        background: #000;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        z-index: -1;
    }

    .page-heading {
        h1 {
            font-size: 80px;
            line-height: 110%;

            @media (max-width: 991px) {
                font-size: 60px;
            }

            @media (max-width: 767px) {
                font-size: 40px;
            }
        }
    }
}
