/* ----------------------------------
   Hero Section - Styles
 ------------------------------------ */

.hero-1 {
    .single-slide {
        padding: 260px 0px 240px;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 1199px) {
            padding: 160px 0px 120px;
        }

        @media (max-width: 991px) {
            padding: 120px 0px 100px;
        }

        @media (max-width: 767px) {
            padding: 50px 0px 30px;
        }

        .hero-contents {
            p {
                color: #fff;
                margin-bottom: 5px;
            }

            h1 {
                color: #fff;
                font-size: 70px;
                line-height: 80px;
                letter-spacing: -3px;
                text-shadow: 2px 2px 4px #000000;

                @media (max-width: 1199px) {
                    font-size: 110px;
                }

                @media (max-width: 991px) {
                    font-size: 60px;
                    line-height: 80px;
                }

                @media (max-width: 767px) {
                    line-height: 55px;
                    font-size: 40px;
                }
            }

            a {
                margin-top: 40px;
                margin-right: 20px;
            }
        }
    }
}

