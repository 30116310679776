.shipping-section{
    position: relative;
    //background-image: url('../../../assets/img/shipping.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    height: 100%;
    width: 100%;

}