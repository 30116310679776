/* ----------------------------------
	Contact Us Section Styles
 ------------------------------------ */


.single-contact-card {
    p{
        font-size: 20px !important;
      }
    background-color: #f8f8f8;
    padding: 40px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out;

    @media (max-width: 585px) {
        padding: 30px;
    }

    &:hover {
        background-color: #202426;

        .icon {
            color: #fff !important;
            background-color: $theme-color !important;
        }

        .bottom-part {
            .icon {
                color: $theme-color !important;
                background-color: #fff !important;
            }
        }

        p,
        span {
            color: #c5c7c8;
        }
        h4 {
            color: #fff;
        }
    }

    .top-part {
        overflow: hidden;
        .icon {
            width: 70px;
            height: 70px;
            font-size: 20px;
            line-height: 70px;
            border-radius: 50%;
            background-color: #fff;
            text-align: center;
            float: left;
            color: $theme-color;
            overflow: hidden;
            margin-right: 20px;
        }
        .title {
            overflow: hidden;
        }
    }
    .bottom-part {
        margin-top: 25px;
        overflow: hidden;

        .icon {
            width: 50px;
            height: 50px;
            font-size: 14px;
            line-height: 50px;
            border-radius: 50%;
            background-color: #fff;
            text-align: center;
            float: right;
            overflow: hidden;
            margin-left: 20px;
        }
        .info {
            display: inline-block;
            overflow: hidden;
        }
    }
}

.contact-map-wrap {
    iframe {
        height: 450px;

        @media (max-width: 991px) {
            height: 300px;
        }
    }
}


.location-img{
    padding-top: 20px;
    text-align: center;
}