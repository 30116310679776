.LogoBanner-wrapper{
    background-color: #960000;
    padding-top: 20px;
    padding-bottom: 20px;
    .container{
        width: 100%;
        .row{
            h2{
                color: #fff;
            }

            @media (max-width: 991px) {
                h2{
                    font-size: 26px;
                }
            }
        
            @media (max-width: 767px) {
                h2{
                    font-size: 18px;
                }
            }
        }
    }
}