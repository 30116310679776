
.footer-1 {
    position: relative;

    .footer-widgets {
        position: relative;
        z-index: 1;
        background-image: url('../../../assets/img/footer-bg.webp');
        background-repeat: no-repeat;
        background-size: cover;
        .container{
            .row{
                justify-content: center;
            }
            .footer-description{
                padding-top: 25px;
                padding-bottom: 5px;
                text-align: center;
                p{
                    color: #cecece;
                }
            }
        }
    }

    .scroll-up-btn {
        text-align: end;
    }

    .copyright-info {
        @media (max-width: 991px) {
            text-align: center;
        }
    }

    .footer-bottom {
        background-color: $black;
        z-index: 1;

        @media (max-width: 767px) {
            text-align: center;

            .scroll-up-btn {
                display: none;
            }
        }
    }
    .row{
        .footer-bg-img{
            width: 30%;
            @media (max-width: 991px) {
                width: 50%;
              }
        
              @media (max-width: 767px) {
                width: 60%;
              }
        }
    }

    .line-color{
        margin-top: 15px;
        background-color: #fff;
        height: 1px;
    }

    

    .footer-logo {
        a {
            position: absolute;
            width: 310px;
            background-color: #960000;
            height: 120px;
            line-height: 120px;
            text-align: center;
            display: inline-block;
            bottom: 0;
            z-index: 22;
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: 991px) {
                position: inherit;
                transform: inherit;
                top: inherit;
                height: 100px;
                line-height: 100px;
                margin-top: 20px;

                &::before,
                &::after {
                    display: none;
                }
            }

            &::before {
                position: absolute;
                width: 0;
                height: 0;
                border-bottom: 26px solid #ffffff;
                border-left: 26px solid transparent;
                content: "";
                right: 100%;
                top: 0;
                z-index: -1;
            }

            &::after {
                position: absolute;
                width: 0;
                height: 0;
                border-bottom: 26px solid #ffffff;
                border-right: 26px solid transparent;
                content: "";
                left: 100%;
                top: 0;
                z-index: -1;
            }
        }
    }
}

.footer-widgets {
    padding-top: 55px;
    padding-bottom: 100px;

    @media (max-width: 991px) {
        padding-bottom: 80px;
    }
}


.footer-wrap {
    .footer-bottom {
        padding: 23px 0px;
        text-align: -webkit-center;
        p,
        a {
            color: $white;
        }

        a {
            font-weight: 400;
        }
    }

    .single-footer-wid {
        margin-top: 40px;

        .wid-title {
            margin-bottom: 40px;
        }

        &.site_info_box {
            text-align: center;
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
            background-color: #fff;
            position: relative;
            margin-right: 40px;
            padding: 60px 40px;
            margin-top: -80px;
            z-index: 9;

            .f_logo {
                margin-bottom: 30px;
                display: block;
            }

            &::before {
                position: absolute;
                width: 100%;
                height: 6px;
                background-color: $theme-color-2;
                content: "";
                top: 0;
                left: 0;
            }

            p {
                color: $textcolor;
                font-size: 14px;
            }

            .social_link {
                margin-top: 40px;

                @media (max-width: 767px) {
                    margin-top: 30px;
                }

                a {
                    background-color: $section-bg;
                    width: 40px;
                    height: 40px;
                    line-height: 41px;
                    border-radius: 50px;
                    font-size: 14px;
                    transition: all 0.3s ease-in-out;
                    text-align: center;
                    display: inline-block;
                    color: $second-color;
                    margin-right: 5px;

                    &:hover {
                        color: #fff;
                        background-color: $theme-color-2;
                    }
                }
            }
        }

        &.site_info_widget {
            .single-contact-info {
                overflow: auto;
                margin-bottom: 20px;
                border-bottom: 1px solid #282828;
                padding-bottom: 25px;
                align-items: center;
                display: flex;

                &:last-child {
                    border: 0;
                    margin-bottom: 0;
                }

                &:hover {
                    .icon {
                        background-color: #fff;
                        color: $theme-color;
                    }
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #fff;
                }
                span {
                    display: block;
                    color: #cacaca;
                    font-size: 14px;
                }
                .icon {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    color: #000;
                    background-color: $theme-color;
                    float: left;
                    text-align: center;
                    overflow: hidden;
                    font-size: 16px;
                    margin-right: 20px;
                    border-radius: 10px;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
        .social_link {
            margin-top: 40px;
            @media (max-width: 767px) {
                margin-top: 30px;
            }
            a {
                background-color: #0f0f0f;
                width: 60px;
                height: 60px;
                line-height: 60px;
                border-radius: 10px;
                font-size: 16px;
                transition: all 0.3s ease-in-out;
                text-align: center;
                display: inline-block;
                color: #fff;
                margin-right: 5px;
                &:hover {
                    color: #fff;
                    background-color: $theme-color;
                }
            }
        }
        ul {
            li {
                > a {
                    color: #fff;
                    margin-bottom: 15px;
                    display: block;
                    transition: all 0.3s;
                    font-weight: 500;
                    text-transform: capitalize;
                    @media (max-width: 1191px) {
                        margin-bottom: 20px;
                    }
                    @media (max-width: 767px) {
                        margin-bottom: 15px;
                    }
                    &:hover {
                        color: $theme-color;
                        padding-left: 10px;
                    }
                }
                &:last-child {
                    a {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}



.newsletter_widget {
    .newsletter_box {
        form {
            height: 64px;
            position: relative;
            display: flex;
            margin-top: 25px;
            margin-bottom: 10px;

            input {
                border: 0px;
                width: 80%;
                position: relative;
                border-style: solid;
                border-width: 2px;
                border-right-width: 0px;
                border-color: rgb(58, 58, 58);
                border-radius: 10px 0px 0px 10px;
                background: #0a0a0a;
                padding-left: 25px;
                padding-right: 15px;
                line-height: 1;
                color: #fff;

                &::placeholder {
                    color: #999;
                }
            }

            button {
                width: 20%;
                height: 100%;
                background: $theme-color;
                color: #000;
                display: inline-block;
                border-radius: 0px 10px 10px 0px;
                font-size: 18px;
                line-height: 1;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #000;
                }
            }
        }
        span {
            color: #cacaca;
        }
    }
}
