/* ------------------------------------------------------------------
   Template Default Button, Input Styles - Value Change with Your's Need
 -------------------------------------------------------------------- */
.btnd {
    line-height: 1;
    display: inline-block;
    @include transition(0.4s);
    text-transform: capitalize;
}

.theme-btn {
    color: $white;
    @extend .btnd;
    padding: 24px 36px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    background-color: $theme-dark;
    border: 2px solid $theme-dark;
    transition: all 0.3s ease-in-out;

    @media (max-width: 991px) {
        padding: 16px 30px;
        font-weight: 400;
        border-radius: 5px;
    }

    @media (max-width: 767px) {
        padding: 14px 28px;
        font-size: 15px;
    }

    &.theme-2 {
        background-color: $theme-color-2;
        border: 2px solid $theme-color-2;
        color: #fff;
        border-radius: 50px;
        padding: 22px 40px;

        &:hover {
            background-color: $second-color;
            border: 2px solid $second-color;
        }

        &.white {
            background: #fff;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
            color: $second-color;
            border-color: #fff;

            &:hover {
                background-color: $second-color;
                color: #fff;
                border-color: $second-color;
            }
        }
    }

    &.theme-3 {
        -webkit-clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
        clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
        border-radius: 0px;
        padding: 22px 50px;
    }

    @media (max-width: 580px) {
        padding: 16px 35px;
    }

    @media (max-width: 480px) {
        padding: 15px 30px;
        font-size: 15px;
    }

    i {
        margin-left: 15px;
    }

    &.no-fil {
        background-color: transparent;
        border-color: rgba($color: #fff, $alpha: 0.2);
        box-shadow: inherit;
        margin-left: 15px;

        @media (max-width: 500px) {
            margin-left: 0;
        }

        &:hover {
            background-color: $theme-color;
            border: 2px solid $theme-color;
        }
    }

    &.black {
        background-color: #000;
        border-color: #000;
        box-shadow: inherit;
        color: $white;

        &:hover {
            background-color: $theme-color;
            border: 2px solid $theme-color;
        }
    }

    &.minimal-btn {
        color: #fff;
        border: 2px solid #ebebeb;
        background: transparent;
        box-shadow: inherit;

        &:hover {
            background-color: $theme-color;
            border: 2px solid $theme-color;
            box-shadow: inherit;
            color: $white;
        }
    }

    &:hover {
        background-color: #000;
        border: 2px solid #000;
        box-shadow: inherit;
        color: $white;
    }
}
