.BuildingMaterials-section {
  p{
    font-size: 20px !important;
  }
  @media (max-width: 991px) {
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 28px;
    }
  }

  .Product1-header {
    padding: 15px !important;

    h2 {
      font-size: 28px;
      color: $theme-color;
    }
  }

  .BrickBlock-section {
    h2 {
      color: $white;
      border-radius: 10px;
      font-weight: 700;
      background-color: $theme-color;
      padding: 20px;
    }

    .section-title {
      p {
        padding-top: 15px;
        color: $black;
      }
    }

    .content_one {
      margin-bottom: 80px;
    }
    .content {
      padding-top: 15px;
      h3 {
        color: $black;
      }
      p {
        padding-top: 15px;
        color: $black;
      }
    }
    .content_img {
      border-style: solid;
      border-width: 10px;
      border-color: #fff;
      border-radius: 20px;
      background-color: #eee;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;

      
    }
  }

  .brickShowPattern{
    h2 {
      color: $white;
      font-weight: 700;
      border-radius: 10px;
      background-color: #960000;
    }
    p {
      padding-top: 15px;
      color: $black;
    }

    .img {
      object-fit: cover;
      border-style: solid;
      border-width: 10px;
      border-color: #fff;
      border-radius: 20px;
      background-color: #eee;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
      @media (max-width: 991px) {
      }

      @media (max-width: 767px) {
      }
    }
  }

  .mon-brick {
    h2 {
      color: $white;
      font-weight: 700;
      border-radius: 10px;
      background-color: #960000;
    }
    p {
      padding-top: 15px;
      color: $black;
    }

    .mon-brick-content {
      // background-color: $theme-color;
      border-radius: 10px;
      .mon-brick-content-block {
        background-color: $section-bg;
        border-radius: 10px;
        height: 250px;
        padding: 20px;

        @media (max-width: 991px) {
          height: 250px;
        }
  
        @media (max-width: 767px) {
          height: 350px;
        }
      }
    }
    .content_img {
      border-style: solid;
      border-width: 10px;
      border-color: #fff;
      border-radius: 20px;
      background-color: #eee;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);

      @media (max-width: 767px) {
      }
    }
  }

  .lightweight-brick {
    h2 {
      color: $white;
      font-weight: 700;
      border-radius: 10px;
      background-color: #960000;
      padding: 20px;
      margin-bottom: 15px;
    }

    .content-block {
      background-color: $section-bg;
      border-radius: 10px;
      height: 250px;
      padding: 20px;

      @media (max-width: 991px) {
        height: 200px;
      }

      @media (max-width: 767px) {
        height: 300px;
      }
    }
  }
  p {
    color: $black;
  }

  .Concrete-section {
    h2 {
      color: $white;
      font-weight: 700;
      border-radius: 10px;
      background-color: $theme-color;
      padding: 20px;
    }
    p {
      color: $black;
      padding: 10px;
    }
    .content_img {
      background-size: cover;
      background-repeat: no-repeat;
    }

    .content_img {
      border-style: solid;
      border-width: 10px;
      border-color: #fff;
      border-radius: 20px;
      background-color: $theme-color;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
      height: 315px;

      @media (max-width: 767px) {
        height: 300px;
      }
    }
    .img {
      border-radius: 20px;
      height: 400px;
      block-size: auto;
    }
    h3 {
      color: $black;
    }
    h4 {
      color: $black;
      font-weight: 400;
    }
    table {
      width: 100%;
      color: $black;
    }
    td {
      border: 1px solid black;
      padding: 5px;
    }
  }

  .Pvc-section {
    .text_header {
      h2 {
        background-color: $theme-color;
        color: $white;
        text-align: center;
      }
    }
    h2 {
      color: $theme-color;
      font-weight: 700;
      border-radius: 10px;
      padding: 20px;
    }
    p {
      color: $black;
      padding: 10px;
    }
    .content_img {
      background-size: cover;
      background-repeat: no-repeat;
    }

    .content_img {
      border-style: solid;
      border-width: 10px;
      border-color: #fff;
      border-radius: 20px;
      background-color: $theme-color;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
      height: 370px;

      @media (max-width: 767px) {
        height: 300px;
      }
    }
    .img {
      border-radius: 20px;
      height: 400px;
      block-size: auto;
    }
    h3 {
      color: $black;
    }
    h4 {
      color: $black;
      font-weight: 400;
    }
  }

  .RockAndSandAndMortar-section {
    .text_header {
      h2 {
        background-color: $theme-color;
        color: $white;
        text-align: center;
        padding: 20px;
        font-weight: 700;
        border-radius: 10px;
      }
    }
    .content {
      h2 {
        color: $theme-color;
        font-weight: 700;
        border-radius: 10px;
        padding: 20px;
      }
      p {
        color: $black;
        padding-top: 15px;
      }
      .content_img {
        background-size: cover;
        background-repeat: no-repeat;
      }

      .content_img {
        border-style: solid;
        border-width: 10px;
        border-color: #fff;
        border-radius: 20px;
        background-color: $theme-color;
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        height: 370px;

        @media (max-width: 767px) {
          height: 300px;
        }
      }
      .img {
        border-radius: 20px;
        height: 400px;
        block-size: auto;
      }
      h3 {
        color: $black;
      }
      h4 {
        padding-top: 15px;
        color: $theme-color;
        font-weight: 600;
        font-size: 30px;
      }
    }
  }

  .MarbleHorseTable-section {
    .text_header {
      h2 {
        background-color: $theme-color;
        color: $white !important;
        text-align: center;
        padding: 20px;
        font-weight: 700;
        border-radius: 10px;
      }
    }
    .content {
      h2 {
        color: $theme-color;
        font-weight: 700;
        border-radius: 10px;
        padding: 20px;
      }
      p {
        color: $black;
        padding-top: 15px;
      }
      .content_img {
        background-size: cover;
        background-repeat: no-repeat;
      }

      .content_img {
        border-style: solid;
        border-width: 10px;
        border-color: #fff;
        border-radius: 20px;
        background-color: $theme-color;
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        height: 370px;

        @media (max-width: 767px) {
          height: 300px;
        }
      }
      .img {
        border-radius: 20px;
        height: 400px;
        block-size: auto;
      }
      h3 {
        color: $black;
      }
      h4 {
        line-height: 40px;
        padding-top: 30px;
        color: $theme-color;
        font-weight: 600;
        font-size: 28px;
      }
    }

    .MarbleHorseTable-carousel-wrapper {
      &.owl-carousel {
        z-index: 0;
        padding-left: 20px;
        padding-right: 20px;
      }

      .center {
        .single-project-item {
          .project-details {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .single-project-item {
        margin-top: 30px;
        margin-bottom: -80px;

        .project-thumb {
          height: 350px;
          background-color: #eee;
          position: relative;

          @media (max-width: 991px) {
            height: 300px;
          }
        }

        .project-details {
          position: relative;
          background-color: #fff;
          width: 90%;
          margin: 0px auto;
          z-index: 3;
          box-shadow: $box-shadow;
          padding: 25px 35px;
          margin-top: -60px;
          z-index: 1;
          opacity: 0;
          visibility: hidden;
          transition: all 0.4s ease-in-out;
          transform: translateY(30px);

          &::before {
            position: absolute;
            width: 90%;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%);
            background-color: $theme-color-3;
            z-index: -1;
            content: "";
            height: 10px;
          }

          .icon {
            display: inline-block;
            position: absolute;
            right: 30px;
            top: 0;
            padding: 10px 30px;
            background-color: $theme-color-3;
            color: #001248;
            text-align: center;
            transform: translateY(-50%);
            -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
            clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
          }

          .meta {
            span {
              &:last-child {
                color: #001248;
                font-weight: 600;
              }
            }
          }

          h3 {
            color: #001248;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: $theme-color-3;
            }
          }
        }
      }
    }
  }

  .Iron-section{
    h2 {
      color: $white;
      border-radius: 10px;
      font-weight: 700;
      background-color: $theme-color;
      padding: 20px;
      margin-bottom: 10px;
    }

    .section-title {
      p {
        padding-top: 15px;
        color: $black;
      }
    }

    .content_one {
      margin-bottom: 80px;
      h5{
        font-size: 18px;
        font-weight: 300;
      }
    }
    .content {
      padding-top: 15px;
      h3 {
        color: $black;
      }
      
      p {
        padding-top: 15px;
        color: $black;
      }
    }
    .content_img {
      border-style: solid;
      border-width: 10px;
      border-color: #fff;
      border-radius: 20px;
      background-color: #eee;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
      height: 475px;
      background-size: cover;

      @media (max-width: 767px) {
        height: 300px;
      }
    }

    .iron-nature{
      background-color: $section-bg;
      padding-top: 25px;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
      margin-top: 30px;
      h4{
        font-weight: 400;
        font-size: 18px;
      }
      h3{
        color: $theme-color;
      }
    }

    .iron-type{
      li{
        padding-left: 25px;
        color: #202020;
      }
      h5{
        font-weight: 400;
        font-size: 18px;
      }
      
      p{
        font-size: 18px;
        font-weight: 300;
      }
    }

  }
}
