.faq-section {
    .content-block {
        .bg-img {
            position: relative;
        }
        .man {
            border-style: solid;
            border-width: 4px;
            border-color: rgb(255, 255, 255);
            border-radius: 50%;
            background-color: rgb(191, 191, 191);
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
            position: absolute;
            width: 42px;
            height: 42px;
            top: 50px;
            left: 80px;
            animation: bounce 3s infinite linear;

            &-2 {
                right: 140px;
                left: auto;
                top: 80px;
                animation: bounce 5s infinite linear;
            }

            &-3 {
                left: 25%;
                top: auto;
                bottom: 60px;
                animation: bounce 4s infinite linear;
            }
        }
    }
}

.accordion {
    color: #777;

    .card {
        margin-top: 10px;
        border: none;
    }

    .card-header {
        padding: 0px 28px;
        border: none;
        background-color: transparent;

        @media (max-width: 480px) {
            padding: 0 15px;
        }

        a {
            display: block;
            font-weight: 600;
            line-height: 50px;
            padding: 10px 0px;
            position: relative;
            color: #000;
            font-size: 18px;

            &:after {
                content: "\f067";

                /* <i className="fal fa-angle-down"></i> */
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                right: 0;
                top: 50%;
                font-weight: 400;
                transform: translateY(-50%);
                color: $theme-color;
            }

            &[aria-expanded="true"]:after {
                content: "\f068";

                /* <i className="fal fa-angle-up"></i> */
            }

            @media (max-width: 767px) {
                line-height: 40px;
                font-size: 15px;
            }

            &:hover {
                color: $theme-color;
            }
        }
    }

    .card-body {
        padding-left: 28px;
        padding-top: 10px;
        padding-bottom: 30px;
    }
}



.faq-section {
    padding-top: 90px;

    @media (max-width: 991px) {
        padding-top: 70px;
    }

    @media (max-width: 767px) {
        padding-top: 50px;
    }

    .accordion {
        .card {
            margin-top: 30px;
            border: none;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
            background-color: #fff;
        }
        .card-header {
            padding: 0px 28px;
            border: none;
            background-color: #fff;

            @media (max-width: 480px) {
                padding: 0 15px;
            }

            a {
                display: block;
                line-height: 50px;
                padding: 10px 0px;
                position: relative;

                &:after {
                    content: "\f067";
                    font-family: $fa;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);

                    @media (max-width: 767px) {
                        right: -28px;
                    }

                    @media (max-width: 500px) {
                        right: -10px;
                    }
                }

                &[aria-expanded="true"]:after {
                    content: "\f068";
                }

                @media (max-width: 767px) {
                    line-height: 40px;
                    font-size: 16px;
                    padding: 6px 0px;
                }

                &:hover {
                    color: $theme-color;
                }
            }
        }

        .card-body {
            padding-left: 28px;
            padding-top: 10px;
            padding-bottom: 30px;
        }
    }
}
