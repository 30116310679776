.portfolio-section {
  .swiper-container {
    max-width: 100%;
    height: 100%;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 5rem;
    height: 4rem;
    font-size: 2rem;
    position: absolute;
    left: auto;
    top: 90%;
    right: 180px;
    background-repeat: no-repeat;
    background-size: 30% auto;
    background-position: center;

    @media (max-width: 575px) {
      width: 4rem;
      height: 3rem;
    }

    @media (max-width: 400px) {
      top: 97%;
      margin-bottom: 2rem;
    }
  }

  .swiper-button-next {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    right: 100px;
    background-image: url("../../img/icon/right-arrow.webp");
    background-color: #fff;

    @media (max-width: 575px) {
      right: 10px;
    }
  }

  .swiper-button-prev {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-image: url("../../img/icon/left-arrow.webp");
    background-color: #fff;
    @media (max-width: 575px) {
      right: 70px;
    }
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: "";
  }
}


// ACCORDION STYLING
.accordion {
  color: #777;
  border: none;

  &__item {
    box-shadow: $box-shadow-2;
    background-color: #fff;
    margin-bottom: 2rem;
    border: none;
  }

  &__button {
    background-color: #fff;
    display: block;
    font-weight: 600;
    line-height: 50px;
    padding: 10px 28px;
    position: relative;
    color: #000;
    font-size: 18px;
    border: none;

    &:hover {
      background-color: white;
      color: $theme-color;
      transition: 0.3s;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
    }
  }

  &__button:before {
    position: absolute;
    top: 40%;
    right: 20px;
    color: $theme-color;
  }

  &__panel {
    padding-left: 28px;
    padding-top: 10px;
    padding-bottom: 30px;
  }

}

