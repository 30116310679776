.Pagoda-section {
  p{
    color: black;
    font-size: 20px !important;
  }
  @media (max-width: 991px) {
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 28px;
    }
  }

  .Product1-header {
    padding: 15px !important;

    h2 {
      font-size: 28px;
      color: $theme-color;
    }
  }

  .Pagoda-carousel-wrapper {
    &.owl-carousel {
      z-index: 0;
      padding-left: 20px;
      padding-right: 20px;
    }

    .center {
      .single-project-item {
        .project-details {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .single-project-item {
      margin-top: 30px;
      text-align: -webkit-center;

      .project-thumb {
        width: 300px;
        height: 300px;
        object-fit: contain;
        background-color: #eee;
        background-size: cover;
        position: relative;

        @media (max-width: 991px) {
        }
      }

      .project-details {
        position: relative;
        background-color: #fff;
        width: 90%;
        margin: 0px auto;
        z-index: 3;
        box-shadow: $box-shadow;
        padding: 25px 35px;
        margin-top: -60px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in-out;
        transform: translateY(30px);

        &::before {
          position: absolute;
          width: 90%;
          left: 50%;
          bottom: -10px;
          transform: translateX(-50%);
          background-color: $theme-color-3;
          z-index: -1;
          content: "";
          height: 10px;
        }

        .icon {
          display: inline-block;
          position: absolute;
          right: 30px;
          top: 0;
          padding: 10px 30px;
          background-color: $theme-color-3;
          color: #001248;
          text-align: center;
          transform: translateY(-50%);
          -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
          clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
        }

        .meta {
          span {
            &:last-child {
              color: #001248;
              font-weight: 600;
            }
          }
        }

        h3 {
          color: #001248;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $theme-color-3;
          }
        }
      }
    }
  }

  .text_header {
    h2 {
      color: $theme-color;
      text-align: center;
      padding-bottom: 15px;
      font-weight: 600;
      border-radius: 10px;
    }
  }

  .content_one {
    background-color: $section-bg;
    padding: 25px;
    border-radius: 10px;
  }

  .img {
    height: 300px;
    width: 300px;
    object-fit: cover;
    background-repeat: no-repeat;
  }
}
